import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Layout } from './src/components/layout';

const wrapPageElement = ({ element, props }) => (
  <GoogleReCaptchaProvider reCaptchaKey="6LcpGM8ZAAAAAAxPepnhOdvIe2LfdXaXw0iU-q4X">
    <Layout {...props}>{element}</Layout>
  </GoogleReCaptchaProvider>
);

export default wrapPageElement;
